import { getFluidTypographyStyles } from '@thepuzzlers/pieces';
import { merge } from 'theme-ui';
// client imports
import { fontFamilies } from './fonts/fontFamilies';
import { typographyConfig } from './typographyConfig';

const fluidTypography = getFluidTypographyStyles({
  typographyConfig,
  fontFamilies
});

// Overwrite styles that are specific to bold or normal variants here:
const typography = merge(fluidTypography, {
  // Global Paragraph
  'global/p': {
    normal: {
      fontSize: ['2rem', '2.2rem', '2.4rem', '2rem', '2.2rem', '2.4rem'],
      fontFamily: 'primary.normal',
      lineHeight: 1.5
    }
  }
});

export { typography };
